import React, { useEffect, useMemo, useState } from "react";
import CardConsultDetailUX from "./CardConsultDetailUX";
import ModCancelConsultAppointmentUX from "./ModCancelConsultAppointmentUX";
import ModConsultDoctorListUX from "./ModConsultDoctorListUX";
import {
  Dimmer,
  FormField,
  FormGroup,
  Icon,
  Input,
  Label,
  Loader,
  Modal,
} from "semantic-ui-react";

import TimeTextBox from "react-lib/apps/common/TimeTextBox";
import DateTextBox from "react-lib/apps/common/DateTextBox";

import SnackMessage from "react-lib/apps/common/SnackMessage";
import ModConsultAppointmentListUX from "./ModConsultAppointmentListUX";
import moment from "moment";
import { adToBe } from "react-lib/utils/dateUtils";
import {
  APPOINTMENT_STATUS,
  APPOINTMENT_STATUS_NAME,
} from "../HISV3/DPO/sequence/ConsultationList";
import CardConsultCancelDetail from "../HISV3/DPO/CardConsultCancelDetail";
import { makeStyles } from "@mui/styles";
import { useIntl } from "react-intl";

const CANCEL_OPTIONS = [
  { key: 1, text: "แพทย์หรือแผนกต้นทาง", value: "แพทย์หรือแผนกต้นทาง" },
  { key: 2, text: "แพทย์หรือแผนกปลายทาง", value: "แพทย์หรือแผนกปลายทาง" },
];

const useStyles = makeStyles((theme) => ({
  disableStyle: {
    "&.ui.disabled.input": {
      backgroundColor: "#DFDFDF",
      opacity: "0.8",
    },
    "&.ui.disabled.dropdown": {
      cursor: "default",
      pointerEvents: "none",
      backgroundColor: "#DFDFDF",
      opacity: "0.45",
    },
  },
}));
const CARD_KEY: string = "CardConsultDetail";

type CardConsultDetailProps = {
  // function
  onEvent: any;
  setProp: any;
  onClose?: any;
  onSelectDateTime: any;
  onConfirmAppointmentSuccess: any;
  onRefreshAppointment?: any;
  forward?: any;

  // options
  divisionList?: any;
  masterOptions?: any;
  mapProviderOptions?: any;

  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;

  // data
  emrId?: number | null;
  refreshId?: number | null;
  selectedConsultId?: number | null;
  selectedAppointment?: {
    id: number | null;
    patientAppointment: number | null;
    status: any;
    patient_name: string;
    patient_hn: string;
    division_service_block?: number | null;
    display_info?: any;
    status_note?: string | null;
    updated?: any;
    edit_user_employee_name?: string;
    date?: string;
    related_detail_status?: any;
    provider?: any;
    start?: any;
    end?: any;
  };
  consultData?: any;
  consultDetail?: {
    id: number | null; // DoctorConsult id
    consult_response_id: number | null; // DoctorConsultOrderResponse id
    date: string;
    time: string;
    note: string;
    consult_response_suggestion: string;
    sourceDivision: number | null;
    sourceProviderDoctor: number | null;
    destinationDivision: number | null;
    destinationProviderDoctor: number | null;
    status: any;
    app_start_time: any;
    app_end_time: any;
    consult_status_label: any;
    order_status_name: any;
    order_encounter: any;
    order_div: any;
    en_type: any;
    cancel_note: string;
    cancel_user: string;
  };
  filter?: Record<string, any> | undefined;
  config?: {
    isSource?: boolean;
    sourceReadOnly?: boolean;
    isDestination?: boolean;
    destinationReadOnly?: boolean;
    hideEditSourceNote?: boolean;
    hideDestinationNote?: boolean;
    hideEditDestinationNote?: boolean;
    hideActionBar?: boolean;
    hideConfirmButton?: boolean;
    hideResponseButton?: boolean;
    hideEditDateTimeButton?: boolean;
    hideSaveButton?: boolean;
    hideEditButton?: boolean;
    hideCancelButton?: boolean;
    hideCloseIcon?: boolean;
    hideConsultStatus?: boolean;
    showViewListIcon?: boolean;
    showSelectDoctorButton?: boolean;
    showExtraButton?: boolean;
    hideFinishButton?: boolean;
    hideReverseFinishButton?: boolean;
  };
};

const CardConsultDetail = (props: CardConsultDetailProps) => {
  const intl = useIntl();
  const classes = useStyles();
  const [editNoteMode, setEditNoteMode] = useState<boolean>(false);
  const [noteText, setNoteText] = useState<string>("");
  const [mode, setMode] = useState<string>("");
  // Modal cancel appointment
  const [modCancel, setModCancel] = useState<any>({
    open: false,
    cancelCause: "",
    cancelReason: "",
    username: "",
    password: "",
    cancelBy: "",
  });
  const [showCancelNote, setShowCancelNote] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  // Modal view appointment list
  const [openModAppList, setOpenModAppList] = useState<boolean>(false);
  // Modal doctor consult list
  const [modConsultDoctorList, setModConsultDoctorList] = useState<any>({
    open: false,
    selectedRow: {},
    time: "",
  });

  const [isLoadingOpenEmr, setIsLoadingOpenEmr] = useState<boolean>(false)

  useEffect(() => {
    props.onEvent({
      message: "GetMasterData",
      params: {
        masters: [
          ["divisionTypeClinic", {}],
          ["division", {}],
        ],
      },
    } as any);
  }, []);

  useEffect(() => {
    props.onEvent({
      message: "HandleInitialConsultData",
      params: { action: "INITIAL", consultId: props.selectedConsultId || null },
    });
  }, [props.selectedConsultId, props.refreshId]);

  useEffect(() => {
    if (props.selectedAppointment?.status == 5) {
      setMode("CANCEL");
    } else if (props.consultDetail?.id) {
      setMode("EDIT");
    } else {
      setMode("CREATE");
    }
  }, [props.consultDetail?.id, props.selectedAppointment?.status]);

  const handleSetConsultDetail = (name: string) => (e: any, v: any) => {
    if (name === "destinationDivision") {
      props.onEvent({
        message: "HandleSetConsultDetail",
        params: {
          name,
          value: v.value === "unknown" ? null : v.value,
          divOption: props.masterOptions?.divisionTypeClinic,
        },
      });
    } else {
      props.onEvent({
        message: "HandleSetConsultDetail",
        params: { name, value: v.value === "unknown" ? null : v.value },
      });
    }
  };

  const handleOnModCancelChange = (name: string, value: any) => {
    console.log("handleOnModCancelChange: ", name, value);
    if (name === "open" && value) {
      props.onEvent({
        message: "HandleGetUsername",
        params: {
          callback: (username: string) =>
            setModCancel({
              ...modCancel,
              username,
              [name]: value,
            }),
        },
      });
    } else {
      setModCancel({
        ...modCancel,
        [name]: value,
      });
    }
  };

  const handleOnModCancelClear = () => {
    setModCancel({
      open: false,
      cancelCause: "",
      cancelReason: "",
      username: "",
      password: "",
      cancelBy: "",
    });
  };

  const handleOnConfirmAppointment = () => {
    props.onEvent({
      message: "HandleConfirmConsultAppointment",
      params: {
        consultId: props.consultDetail?.id,
        patientAppointmentId:
          props.selectedAppointment?.patientAppointment ||
          props.selectedAppointment?.id,
        card: CARD_KEY,
        onSuccess: props?.onConfirmAppointmentSuccess || null,
        onRefresh: props?.onRefreshAppointment || null,
        filter: props.filter || {},
      },
    });
  };

  const handleFinishConsult = () => {
    props.onEvent({
      message: "HandleFinishConsultAppointment",
      params: {
        consultId: props.consultDetail?.id,
        card: CARD_KEY,
        data: { action: "FINISH" },
        onSuccess: props?.onConfirmAppointmentSuccess || null,
        onRefresh: props?.onRefreshAppointment || null,
      },
    });
  };

  const handleReverseFinishConsult = () => {
    props.onEvent({
      message: "HandleFinishConsultAppointment",
      params: {
        consultId: props.consultDetail?.id,
        card: CARD_KEY,
        data: { action: "REVERSE_FINISH" },
        onSuccess: props?.onConfirmAppointmentSuccess || null,
        onRefresh: props?.onRefreshAppointment || null,
      },
    });
  };

  const doctorProviderOptions = useMemo(() => {
    return (props.consultData?.providerDoctorOptions || []).concat([
      { key: "unknown", text: "ไม่ระบุแพทย์", value: "unknown" },
    ]);
  }, [props.consultData?.providerDoctorOptions]);

  const appStatusLabel = useMemo(() => {
    let content: string = "";
    let color: string = "";

    if (props.consultDetail?.id) {
      switch (
        parseInt(
          props.selectedAppointment?.related_detail_status ||
            props.selectedAppointment?.status
        )
      ) {
        case parseInt(APPOINTMENT_STATUS.WAIT_CONFIRMED):
          content = APPOINTMENT_STATUS_NAME.WAIT_CONFIRMED;
          color = "yellow";
          break;
        case parseInt(APPOINTMENT_STATUS.CONFIRMED):
          content = APPOINTMENT_STATUS_NAME.CONFIRMED;
          color = "green";
          break;
        case parseInt(APPOINTMENT_STATUS.FINISHED):
          content = APPOINTMENT_STATUS_NAME.CONFIRMED;
          color = "green";
          break;
        case parseInt(APPOINTMENT_STATUS.CANCELED):
          content = APPOINTMENT_STATUS_NAME.CANCELED;
          color = "red";
          break;
        default:
          content = "";
          color = "";
      }
    }
    if (content && color) {
      return color === "red" ? (
        <Label
          content={content}
          color={color as "red"}
          style={{ marginBottom: "10px", cursor: "pointer" }}
          icon="sticky note"
          onClick={() => {
            const app = props.selectedAppointment;
            const statusNote: any = app?.status_note?.split(":");
            const cancelUser = app?.edit_user_employee_name || "";
            const momentDateTime = moment(app?.updated);
            const date = adToBe(momentDateTime.format("DD/MM?YYYY"));
            const time = momentDateTime?.format("HH:mm");

            setModCancel({
              open: true,
              cancelCause: statusNote?.length > 1 ? statusNote[0] : "",
              cancelReason: statusNote?.length > 1 ? statusNote[1] : "",
              cancelBy: (
                <div>
                  <span style={{ fontWeight: "bold", paddingRight: "5px" }}>
                    ยกเลิก โดย
                  </span>
                  {`${cancelUser} ${date} [${time}]`}
                </div>
              ),
            });
            setShowCancelNote(true);
          }}
        />
      ) : (
        <Label
          content={content}
          color={color as "yellow" | "green" | "red"}
          style={{ marginBottom: "10px" }}
        />
      );
    }
    return <></>;
  }, [
    props.selectedAppointment?.status,
    props.consultDetail?.id,
    props.selectedAppointment?.related_detail_status,
  ]);

  const consultStatusLabel = useMemo(() => {
    let content: string = "";
    let color: string = "";
    if (props.consultDetail?.id) {
      content = props.consultDetail?.consult_status_label || "";
      switch (props.consultDetail?.consult_status_label) {
        case "กำลัง Consult":
          color = "yellow";
          break;
        case "Consult เสร็จสิ้น":
          color = "green";
          break;
        default:
          color = "grey";
      }
    }

    if (content && color) {
      return (
        <Label
          content={content}
          color={color as "grey" | "green" | "yellow"}
          style={{ marginBottom: "10px" }}
        />
      );
    }
    return <></>;
  }, [props.consultDetail?.status, props.consultDetail?.id]);

  const extraIcon = useMemo(() => {
    if (props.config?.showExtraButton) {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              textAlign: "center",
              cursor: "pointer",
              marginRight: "10px",
            }}
          >
            <Icon.Group size="large" style={{ width: "auto" }}>
              <Icon name="list" color="green" size="small" />
              <Icon corner="bottom right" name="user" color="green" />
            </Icon.Group>
            <div style={{ fontSize: "8px", color: "#21ba45", textWrap: "nowrap" }}>View PHV</div>
          </div>
          {props.consultDetail?.en_type === "IPD" && (
            <div
              style={{ textAlign: "center", cursor: "pointer", position: "relative" }}
              onClick={() => {
                setIsLoadingOpenEmr(true);

                props.onEvent({
                  message: "HandleConsultListToWard",
                  params: {
                    hn: props.selectedAppointment?.patient_hn?.replaceAll("-", ""),
                    encounter: props.consultDetail?.order_encounter,
                    divisionId: props.consultDetail?.order_div,
                    consultId: props.consultDetail?.consult_id,
                    callback: (isSuccess: boolean) => {
                      setIsLoadingOpenEmr(false);

                      if (isSuccess) {
                        props.forward?.();
                      }
                    },
                    card: CARD_KEY,
                  },
                });
              }}
            >
              <Dimmer active={isLoadingOpenEmr} style={{ marginTop: "-0.35rem" }} inverted>
                <Loader size="mini"></Loader>
              </Dimmer>
              <Icon.Group size="large" style={{ width: "auto" }}>
                <Icon name="file" color="blue" size="small" />
                <Icon corner="bottom right" name="share" color="blue" />
              </Icon.Group>
              <div
                style={{
                  fontSize: "8px",
                  color: "#2185d0",
                  textWrap: "nowrap",
                }}
              >
                Open EMR
              </div>
            </div>
          )}
        </div>
      );
    }
    return <></>;
  }, [
    props.config?.showExtraButton,
    props.consultDetail?.en_type,
    props.consultDetail?.consult_id,
    props.selectedAppointment?.patient_hn,
    isLoadingOpenEmr,
  ]);

  // Mod View consult list
  const viewListIcon = useMemo(() => {
    if (props.consultDetail?.destinationProviderDoctor) {
      return (
        <div
          style={{ textAlign: "center", cursor: "pointer" }}
          onClick={() => {
            setOpenModAppList(true);
          }}
        >
          <Icon.Group size="large" style={{ width: "auto" }}>
            <Icon name="user" color="blue" />
            <Icon corner="top right" name="circle" color="blue" size="tiny" />
            <Icon corner="bottom right" name="list" color="blue" size="small" />
          </Icon.Group>
          <div
            style={{ fontSize: "10px", color: "#2185d0", textWrap: "nowrap" }}
          >
            View list
          </div>
        </div>
      );
    }
    return <></>;
  }, [props.consultDetail?.destinationProviderDoctor]);

  const appointmentListForView = useMemo(() => {
    if (
      props.consultData?.divisionServiceBlockOptions &&
      props.consultDetail?.destinationProviderDoctor &&
      props.selectedAppointment?.division_service_block
    ) {
      const targetData = props.consultData?.divisionServiceBlockOptions?.find(
        (dsb: any) =>
          props.selectedAppointment?.display_info?.start_serial ===
            dsb.start_serial &&
          props.selectedAppointment?.display_info?.end_serial === dsb.end_serial
      );
      if (targetData) {
        return (
          targetData?.providers
            ?.map((item: any) => {
              return item?.dsb?.appointments.map(
                (app: any) =>
                  ({
                    id: app.id,
                    patient_full_name: `${app.first_name} ${app.last_name}`,
                    start_time: moment(app.estimated_at).format("HH:mm"),
                    duration: `${app.estimated_duration} นาที`,
                    appointment_user: app.edit_user_name,
                    booking_date_time: `${adToBe(
                      item?.dsb?.date,
                      "YYYY-MM-DD"
                    )} [${item?.dsb?.start_time} - ${item?.dsb?.end_time}]`,
                  } || [])
              );
            })
            .flat() || []
        );
      }
    }
    return [];
  }, [
    props.consultDetail?.destinationProviderDoctor,
    props.consultData?.divisionServiceBlockOptions,
    props.selectedAppointment,
  ]);

  // mod select consult doctor
  const consultDoctorList = useMemo(() => {
    if (
      props.consultData?.divisionServiceBlockOptions &&
      props.selectedAppointment?.division_service_block
    ) {
      const targetData = props.consultData?.divisionServiceBlockOptions?.find(
        (dsb: any) =>
          props.selectedAppointment?.display_info?.start_serial ===
            dsb.start_serial &&
          props.selectedAppointment?.display_info?.end_serial === dsb.end_serial
      );
      if (targetData) {
        return (
          targetData?.providers?.map((item: any) => {
            const endTime: any = new Date(
              `${item.dsb.date} ${item.dsb.doctor_end_time}`
            );
            const startTime: any = new Date(
              `${item.dsb.date} ${item.dsb.doctor_start_time}`
            );
            const slot =
              (endTime - startTime) / 60000 / item.dsb.doctor_dsb_slot_length;
            return {
              ...item?.dsb,
              doctor_name: item?.dsb?.doctor_provider_name || "",
              dateText: adToBe(item.dsb?.date, "YYYY-MM-DD"),
              chair: item.dsb?.chair_dsb_id || "ว่าง",
              zone: item.dsb?.doctor_dsb_zone_id || "ว่าง",
              appointmentCount: `${item.dsb?.appointments?.length} (${slot})`,
            };
          }) || []
        );
      }
    }
    return [];
  }, [
    props.consultData?.divisionServiceBlockOptions,
    props.selectedAppointment,
  ]);

  const consultDoctorListDetail = useMemo(() => {
    return `${props.selectedAppointment?.date},
    ${moment(
      props.selectedAppointment?.display_info?.start_datetime_iso
    ).format("HH:mm")}
    - ${moment(
      props.selectedAppointment?.display_info?.end_datetime_iso
    ).format("HH:mm")}
    (แพทย์ ${consultDoctorList?.length || 0} คน)`;
  }, [consultDoctorList]);

  const timeOptions = useMemo(() => {
    if (modConsultDoctorList?.selectedRow?.dsb_id) {
      // already appointment
      const row = modConsultDoctorList?.selectedRow;
      const formatDateTime = "YYYY-MM-DDTHH:mm";
      const appointmentTime =
        row?.appointments?.map((item: any) =>
          moment(item.estimated_at).format(formatDateTime)
        ) || [];

      let start: any = new Date(`${row.date} ${row.doctor_start_time}`);
      let end: any = new Date(`${row.date} ${row.doctor_end_time}`);
      let minutesPerSession = row.doctor_dsb_slot_length;
      let options = [];
      let startTime = moment(start);
      let endTime = moment(end);
      // console.log("minutesPerSession: ", minutesPerSession, appointmentTime, startTime)
      while (startTime < endTime) {
        const time = startTime.format("HH:mm");
        const timeText = appointmentTime.some(
          (item: any) => item === startTime.format(formatDateTime)
        )
          ? `${time} (มีนัดหมายแล้ว)`
          : time;
        options.push({ key: time, text: timeText, value: time });
        startTime = startTime.add(minutesPerSession, "minutes");
      }
      return options;
    }
    return [];
  }, [modConsultDoctorList?.selectedRow]);

  const bookingComponent = useMemo(() => {
    if (props.consultDetail?.id) {
      let startTime = "";
      let endTime = "";
      if (props.selectedAppointment?.start) {
        startTime = props.selectedAppointment?.start?.toString();
        endTime = props.selectedAppointment?.end?.toString();
      } else if (props.consultDetail?.app_start_time) {
        startTime = props.consultDetail?.app_start_time.slice(0, 6);
        endTime = props.consultDetail?.app_end_time.slice(0, 6);
      }

      return (
        <div className="ui form">
          <FormGroup inline={true} style={{ marginBottom: "0px" }}>
            {/* <FormField>
              <label>{intl.formatMessage({ id: "วันที่ต้องการส่งปรึกษา" })}</label>
            </FormField> */}
            <FormField width={8}>
              <div style={{ width: "100%" }}>
                <label>{intl.formatMessage({ id: "วันที่ต้องการส่งปรึกษา" })}</label>
                <DateTextBox
                  inputClassNames={classes.disableStyle}
                  style={{ width: "100%" }}
                  disabled={props.config?.destinationReadOnly}
                  inputFluid={true}
                  value={props.consultDetail?.date || props.consultDetail?.app_date}
                />
              </div>
            </FormField>
            {/* <FormField>
              <label>{intl.formatMessage({ id: "เวลา" })}</label>
            </FormField> */}
            <FormField width={6}>
              <div style={{ width: "100%" }}>
                <label>{intl.formatMessage({ id: "เวลา" })}</label>
                {props.consultDetail?.destinationProviderDoctor ? (
                  <TimeTextBox
                    className={classes.disableStyle}
                    style={{ minWidth: "0px", width: "100%" }}
                    disabled={props.config?.destinationReadOnly}
                    value={props.consultDetail?.time || ""}
                  />
                ) : (
                  <Input
                    className={classes.disableStyle}
                    disabled={true}
                    value={`${startTime} - ${endTime}`}
                  />
                )}
              </div>
            </FormField>
          </FormGroup>
        </div>
      );
    }
    return (
      <div
        style={{
          border: "1px solid #ECECEC",
          position: "relative",
          padding: "10px 10px 16px",
        }}
      >
        <div
          style={{
            position: "absolute",
            padding: "10px",
            backgroundColor: "white",
            bottom: "-60%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textWrap: "nowrap",
          }}
        >
          กรอกให้อัตโนมัติ เมื่อเลือกวันเวลานัดหมายจากปฏิทิน
        </div>
        <div className="ui form">
          <FormGroup inline={true} style={{ marginBottom: "0px" }}>
            <FormField>
              <label style={{ textWrap: "nowrap" }}>
                วันที่ต้องการส่งปรึกษา
              </label>
            </FormField>
            <FormField width={5}>
              <DateTextBox
                inputClassNames={classes.disableStyle}
                style={{ width: "100%" }}
                disabled={true}
                inputFluid={true}
                value={props.consultDetail?.date}
              />
            </FormField>
            <FormField>
              <label>{intl.formatMessage({ id: "เวลา" })}</label>
            </FormField>
            <FormField width={5}>
              <TimeTextBox
                className={classes.disableStyle}
                style={{ minWidth: "0px", width: "100%" }}
                disabled={true}
                value={props.consultDetail?.time || ""}
              />
            </FormField>
          </FormGroup>
        </div>
      </div>
    );
  }, [
    props.consultDetail?.id,
    props.consultDetail?.time,
    props.consultDetail?.date,
    props.consultDetail?.destinationProviderDoctor,
  ]);

  // console.log("CardConsultDetail props: ", props);
  return (
    <div>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_KEY}`, null);
          props.setProp(`successMessage.${CARD_KEY}`, null);
        }}
        error={props.errorMessage?.[CARD_KEY]}
        success={props.successMessage?.[CARD_KEY]}
        languageUX={props.languageUX}
      />

      <CardConsultDetailUX
        // data
        consultDetail={{
          ...props.consultDetail,
          destinationProviderDoctor: props?.consultDetail // set default if not select doctor to  ไม่ระบุแพทย์
            ?.destinationProviderDoctor
            ? props?.consultDetail?.destinationProviderDoctor
            : props?.consultDetail?.destinationDivision
            ? "unknown"
            : null,
        }}
        disabledSelectDateTimeButton={!props.consultDetail?.destinationDivision}
        mode={mode}
        // read only data
        config={props.config}
        patientDetail={`${props.selectedAppointment?.patient_name} [${props.selectedAppointment?.patient_hn}]`}
        // options
        divisionTypeClinic={props.masterOptions?.divisionTypeClinic || []}
        divisionList={props.masterOptions?.division || []}
        doctorProviderOptions={doctorProviderOptions}
        doctorOptions={props.masterOptions?.doctor || []}
        // handler
        onClose={props.onClose}
        onDivisionChange={handleSetConsultDetail("destinationDivision")}
        onSelectedProviderDoctor={handleSetConsultDetail(
          "destinationProviderDoctor"
        )}
        onSelectDateTime={props.onSelectDateTime}
        onConfirmAppointment={handleOnConfirmAppointment}
        onSaveConsultAppointment={() => {
          props.onEvent({
            message: "HandleSaveConsultResponse",
            params: {
              consultId: props.consultDetail?.id,
              patientAppointmentId:
                props.selectedAppointment?.patientAppointment ||
                props.selectedAppointment?.id,
              emrId: props.emrId,
              note: props.consultDetail?.note,
              consult_response_suggestion:
                props.consultDetail?.consult_response_suggestion,
              card: CARD_KEY,
              onSuccess: () => {},
            },
          });
        }}
        onEditConsultAppointment={() => {
          props.onEvent({
            message: "HandleEditConsultResponse",
            params: {
              consultId: props.consultDetail?.id,
              patientAppointmentId:
                props.selectedAppointment?.patientAppointment ||
                props.selectedAppointment?.id,
              consult_response_id: props.consultDetail?.consult_response_id,
              consult_response_suggestion:
                props.consultDetail?.consult_response_suggestion,
              card: CARD_KEY,
              onSuccess: () => {},
            },
          });
        }}
        onCancelAppointment={() => {
          handleOnModCancelChange("open", true);
        }}
        onSpecifyAppointment={() => {
          setModConsultDoctorList({ ...modConsultDoctorList, open: true });
        }}
        onFinishConsult={handleFinishConsult}
        onReverseFinishConsult={handleReverseFinishConsult}
        // component
        appStatusLabel={appStatusLabel}
        consultStatusLabel={consultStatusLabel}
        bookingComponent={bookingComponent}
        viewListIcon={viewListIcon}
        consultTextArea={
          <div style={{ display: "flex", padding: "10px" }}>
            <textarea
              rows={8}
              value={props.consultDetail?.note}
              style={{ width: "100%", marginRight: "10px" }}
              onChange={(e: any) =>
                props.onEvent({
                  message: "HandleSetConsultDetail",
                  params: { name: "note", value: e.target.value },
                })
              }
              readOnly={!!props.consultDetail?.id && !editNoteMode}
            />
            {!props.config?.hideEditSourceNote && (
              <>
                {props.consultDetail?.id && editNoteMode ? (
                  <div
                    style={{
                      // position: "absolute",
                      // right: "55px",
                      marginLeft: "-40px",
                      marginTop: "85px",
                    }}
                  >
                    <Icon
                      name="reply"
                      color="grey"
                      size="large"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        props.onEvent({
                          message: "HandleSetConsultDetail",
                          params: { name: "note", value: noteText },
                        });
                        setEditNoteMode(false);
                        setNoteText("");
                      }}
                    />
                    <Icon
                      name="save"
                      color="green"
                      size="large"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        props.onEvent({
                          message: "HandleMakeConsultAppointment",
                          params: {
                            action: "EDIT_NOTE",
                            buttonLoad: "ConsultEditNote",
                          },
                        });
                        setEditNoteMode(false);
                        setNoteText("");
                      }}
                    />
                  </div>
                ) : props.consultDetail?.id &&
                  !editNoteMode &&
                  !props.config?.hideEditDateTimeButton ? (
                  <div
                    style={{
                      // position: "absolute",
                      // right: "55px",
                      marginLeft: "-40px",
                      marginTop: "105px",
                    }}
                  >
                    <Icon
                      name="edit"
                      color="yellow"
                      size="large"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setEditNoteMode(true);
                        setNoteText(props.consultDetail?.note || "");
                      }}
                    />
                  </div>
                ) : (
                  <div />
                )}
              </>
            )}
          </div>
        }
        extraButton={extraIcon}
        reactConsultTextArea={
          <textarea
            rows={6}
            value={props.consultDetail?.consult_response_suggestion}
            style={{ width: "100%" }}
            onChange={(e: any) => {
              props.onEvent({
                message: "HandleSetConsultDetail",
                params: {
                  name: "consult_response_suggestion",
                  value: e.target.value,
                },
              });
            }}
            readOnly={props.config?.hideEditDestinationNote}
          />
        }
        languageUX={props.languageUX}
      />

      <CardConsultCancelDetail
        onCloseMod={handleOnModCancelClear}
        isOpen={modCancel?.open && showCancelNote}
        cancel_note={props.consultDetail?.cancel_note || ""}
        cancel_user={props.consultDetail?.cancel_user || ""}
        languageUX={props.languageUX}
      />

      <Modal open={modCancel?.open && !showCancelNote} size={"small"}>
        <ModCancelConsultAppointmentUX
          // data
          showCancelNote={showCancelNote}
          cancelOptions={CANCEL_OPTIONS}
          cancelCause={modCancel?.cancelCause}
          cancelReason={modCancel?.cancelReason}
          username={modCancel?.username}
          password={modCancel?.password}
          cancelBy={modCancel?.cancelBy}
          visible={visible}
          // function
          onSelectCancelOption={(e: any, v: any) => {
            handleOnModCancelChange("cancelCause", v.value);
          }}
          onReasonChange={(e: any, v: any) => {
            handleOnModCancelChange("cancelReason", v.value);
          }}
          onChangeUsername={(e: any, v: any) => {
            handleOnModCancelChange("username", v.value);
          }}
          onChangePassword={(e: any, v: any) => {
            handleOnModCancelChange("password", v.value);
          }}
          onConfirm={() => {
            props.onEvent({
              message: "HandleCancelConsultAppointment",
              params: {
                ...modCancel,
                patientAppointment:
                  props.selectedAppointment?.patientAppointment || null,
                filter: props.filter || null,
                card: "CardPatientAppointment",
                onSuccess: () => {
                  handleOnModCancelClear();
                },
                onRefresh: props?.onRefreshAppointment || null,
              },
            });
          }}
          onCancel={handleOnModCancelClear}
          // component
          passwordIcon={
            <Icon
              link
              style={{ cursor: "pointer" }}
              name={visible ? "eye" : "eye slash"}
              onClick={() => {
                setVisible(!visible);
              }}
            />
          }
          languageUX={props.languageUX}
        />
      </Modal>

      <Modal open={modConsultDoctorList?.open}>
        <ModConsultDoctorListUX
          // data
          dsbDetail={consultDoctorListDetail}
          consultDoctorList={consultDoctorList}
          time={modConsultDoctorList?.time}
          timeOptions={timeOptions}
          // function
          onTimeChange={(e: any, v: any) =>
            setModConsultDoctorList({ ...modConsultDoctorList, time: v.value })
          }
          onSelectDSBRow={(
            state: any,
            row: any,
            column: any,
            instance: any
          ) => {
            return {
              style: {
                backgroundColor:
                  row?.original?.doctor_dsb_id &&
                  row?.original?.doctor_dsb_id ===
                    modConsultDoctorList?.selectedRow?.doctor_dsb_id
                    ? "#cccccc"
                    : "white",
              },
              onClick: () => {
                setModConsultDoctorList({
                  ...modConsultDoctorList,
                  selectedRow: row?.original,
                  time: "",
                });
              },
            };
          }}
          onSelectDSB={() => {
            props.onEvent({
              message: "HandleMakeConsultAppointment",
              params: {
                selectedEvent: modConsultDoctorList?.selectedRow,
                doctorProvider: modConsultDoctorList?.selectedRow?.doctor_provider_id,
                action: "MAKE_APPOINTMENT",
                time: modConsultDoctorList?.time,
                onSuccess: () => {
                  setModConsultDoctorList({
                    open: false,
                    selectedRow: null,
                    time: "",
                  });
                },
                onRefresh: props?.onRefreshAppointment || null,
                card: CARD_KEY,
              },
            });
          }}
          onCancel={() => {
            setModConsultDoctorList({ open: false, selectedRow: {}, time: "" });
          }}
        />
      </Modal>

      <Modal open={openModAppList} closeOnDimmerClick={false} size="large">
        <ModConsultAppointmentListUX
          showActionButton={true}
          hideConfirmButton={props.selectedAppointment?.status !== 1}
          data={appointmentListForView}
          // function
          onRowClick={(state: any, row: any, column: any, instance: any) => {
            return {
              style: {
                backgroundColor:
                  row?.original?.id &&
                  row?.original?.id === props.selectedAppointment?.id
                    ? "#0d71bb82"
                    : "white",
              },
            };
          }}
          onConfirmAppointment={() => {
            handleOnConfirmAppointment();
            setOpenModAppList(false);
          }}
          onEditAppointmentDateTime={() => {
            props.onSelectDateTime();
            setOpenModAppList(false);
          }}
          onCancelAppointment={() => {
            handleOnModCancelChange("open", true);
            setOpenModAppList(false);
          }}
          onClose={() => {
            setOpenModAppList(false);
          }}
        />
      </Modal>
    </div>
  );
};

export default React.memo(CardConsultDetail);
